import React from 'react'
import Theme1 from '../assets/images/Wild Life.png';
import Theme2 from '../assets/images/Hidden Gems.png';
import Theme3 from '../assets/images/Cruise.png';
import Theme4 from '../assets/images/Pilgrimage.png';
import Theme5 from '../assets/images/Adventure.png';


const Thems = () => {
    return (
        <div className="max-w-[1170px] mx-auto py-5">
            <h1 className='text-[#005293] text-3xl font-medium font-roboto text-center py-4'>Themes You Can Explore</h1>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4 p-3">
                <div className="border-[#BEBCBC] border-2 p-4 m-1 flex flex-col  justify-center items-center">
                    <img src={Theme1} alt="" />
                    <p className='py-2 font-medium'>Wild Life</p>
                </div>
                <div className="border-[#BEBCBC] border-2 p-4 m-1 flex flex-col  justify-center items-center">
                    <img src={Theme2} alt="" />
                    <p className='py-2 font-medium'>Hidden Gems</p>
                </div>
                <div className="border-[#BEBCBC] border-2 p-4 m-1 flex flex-col  justify-center items-center">
                    <img src={Theme3} alt="" />
                    <p className='py-2 font-medium'>Cruise</p>
                </div>
                <div className="border-[#BEBCBC] border-2 p-4 m-1 flex flex-col  justify-center items-center">
                    <img src={Theme4} alt="" />
                    <p className='py-2 font-medium'>Pilgrimage</p>
                </div>
                <div className="border-[#BEBCBC] border-2 p-4 m-1 flex flex-col justify-center items-center">
                    <img src={Theme5} alt="" />
                    <p className='py-2 font-medium'>Adventure</p>
                </div>
            </div>
        </div>
    )
}

export default Thems
