import React, { useState } from 'react';
import ContactUsImg from '../assets/images/contactusimg.png';

const Contact = () => {

    return (
        <div id="contact" className="relative bg-cover bg-center overflow-hidden my-10" style={{ backgroundImage: `url(${ContactUsImg})` }}>
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="max-w-[1170px] mx-auto py-15 p-3 md:flex md:place-content-end relative">
                <div className="md:w-[50%] p-[1rem] relative z-10 flex flex-col justify-center">
                    <h3 className='text-white font-roboto text-2xl py-1'>SHARE YOUR DETAILS AND LET US</h3>
                    <h2 className='text-white font-roboto text-4xl py-1'>PLAN YOUR IDEAL VACATION</h2>
                </div>
                <div className="contact-field md:w-[50%] p-[1rem] relative z-10 inset-0">
                    <form action="https://formspree.io/f/xoqgypza" method="POST">
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            className={`my-4 w-full border-b border-gray-300 pl-4 h-12 block text-black rounded-lg`}
                            autoComplete="off"
                            required
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            className={`my-4 w-full border-b border-gray-300 pl-4 h-12 block text-black rounded-lg`}
                            autoComplete="off"
                            required
                        />
                        <input
                            type="tel"
                            name="number"
                            placeholder="Your Number"
                            className={`my-4 w-full border-b border-gray-300 pl-4 h-12 block text-black rounded-lg`}
                            autoComplete="off"
                            required
                        />
                        <div className="flex items-center">
                            <input type="checkbox"
                                className=""
                                required
                            />
                            <label htmlFor="privacyCheckbox" className="ml-2 text-white">I hereby accept the <span className='text-[#312EE9] underline '> Privacy Policy </span> and authorize Travel Outbound company to contact me.</label>
                        </div>
                        
                        <button
                            type="submit"
                            className="bg-[#11B99F] text-[white] px-10 py-1 my-5 rounded-xl"
                        >
                            Send
                        </button>
                    </form>
                    
                </div>
            </div>
        </div>
    );
};

export default Contact;
