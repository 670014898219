import React from 'react';
import bgholidays from '../assets/images/holiday.png';
import { NavLink } from 'react-router-dom';

const Holidays = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div
      className="bg-cover bg-center p-10"
      style={{ backgroundImage: `url(${bgholidays})` }}
    >
      <div className="max-w-[1170px] mx-auto py-5 p-3 grid grid-cols-1 md:grid-cols-2 gap-4">
       <div className='flex justify-start md:justify-center'>
          <h1 className='text-4xl font-bold text-[white] font-roboto'>PLAN YOUR <br></br> NEXT HOLIDAY</h1>
       </div>
       <div className='flex items-end'>
              <button  onClick={scrollToTop} className='bg-[white] text-[#11B99F] px-5 py-2 rounded-xl'><NavLink to="/contact">BOOK NOW</NavLink></button>
       </div>
      </div>
    </div>
  );
};

export default Holidays;
