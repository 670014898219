import React from 'react'
import featuresimg from '../assets/images/featureimg.png';
import feature1 from '../assets/images/feature1.png';
import feature2 from '../assets/images/feature2.png';
import feature3 from '../assets/images/feature3.png';


const Features = () => {
    return (
        <div
            className="bg-cover bg-center p-10"
            style={{ backgroundImage: `url(${featuresimg})` }}
        >
            <div className="max-w-[1170px] mx-auto py-5 p-3 grid grid-cols-1 md:grid-cols-3 gap-4  mt-[0] md:mt-[12rem]">
                <div className=' h-[13rem] bg-[white] text-[black] flex flex-col items-center justify-center text-center p-5 m-0 md:m-10'>
                     <img src={feature1} alt="" className='w-[30%] my-3' />
                     <p className='text-2xl font-semibold'>AFFORDABLE PRICE GUARANTEE</p>
                </div>

                <div className=' h-[13rem] bg-[white] text-[black] flex flex-col items-center justify-center text-center p-5 m-0 md:m-10'>
                <img src={feature2} alt="" className='w-[30%] my-3' />
                <p className='text-2xl font-semibold'>WIDE VARIETY OF DESTINATIONS</p>
                </div>

                <div className=' h-[13rem] bg-[white] text-[black] flex flex-col items-center justify-center text-center p-5 m-0 md:m-10'>
                <img src={feature3} alt="" className='w-[30%] my-3' />
                <p className='text-2xl font-semibold'>HIGHLY QUALIFIED SERVICE</p>
                </div>
            </div>

        </div>
    )
}

export default Features