import React from 'react'
import Logo from '../assets/images/logo.png';
import InstagramLogo from '../assets/images/instagram.png';
import FbLogo from '../assets/images/Facebook.png';
import WhatsappLogo from '../assets/images/Whatsapp.png';
import { NavLink } from 'react-router-dom';

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

    return (
        <div className="footer bg-[#11B99F]">
            <div className="max-w-[1170px] mx-auto py-5 p-3 grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="footerlogos text-left md:text-center md:mx-[auto]">
                    <div className="interior-logo mt-2">
                        <img src={Logo} alt="My Image" className='w-[250px] mt-[2rem]' />
                    </div>
                    <p className="md:text-center text-white py-2 font-canada">Social Networks</p>
                    <div className="social-icon-box flex justify-start md:justify-center gap-2">
                        <a target='blank' href="https://www.instagram.com/_traveloutbound_/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D">
                            <img src={InstagramLogo} alt="My Image" className='w-[25px] h-[25px]' />
                        </a>
                        <a target='blank' href="https://www.facebook.com/traveloutbound97">
                            <img src={FbLogo} alt="My Image" className='w-[25px] h-[25px]' />
                        </a>
                    </div>
                </div>

                <div className="linksbox text-white mt-2 text-left md:text-center md:mx-[auto]">
                    <div className='text-left'>
                        <p className='block py-2 font-canada hover:cursor-pointer'><NavLink  onClick={scrollToTop} to="/about">About Us</NavLink></p>
                        <p className='block py-2 font-canada hover:cursor-pointer'><NavLink  onClick={scrollToTop} to="/services">Services</NavLink></p>
                        <p className='block py-2 font-canada hover:cursor-pointer'><NavLink  onClick={scrollToTop} to="/itinerary">Itinerary</NavLink></p>
                        <p className='block py-2 font-canada hover:cursor-pointer'><NavLink  onClick={scrollToTop} to="/contact">Contact Us</NavLink></p>
                    </div>
                </div>

                <div className="contactinfo mt-2">
                    <h2 className="text-[#F8CB89] text-[30px]">CONTACT US</h2>
                    <div className="address text-white py-1">
                        <h6 className='font-canada'>Address</h6>
                        <p className='font-canada'>Boraspada Rd, Kandivali, Sector 5 Charkop, Kandivali West, Mumbai, Maharashtra 400067</p>
                    </div>
                    <div className="phone text-white py-1">
                        <h6 className='font-canada'>Phone</h6>
                        <p className='font-canada'>+91 9619932786</p>
                        <p className='font-canada'>+91 9619932771</p>
                    </div>
                    <div className="email text-white py-1">
                        <h6 className='font-canada'>Email</h6>
                        <p className='font-canada'>info@traveloutbound.com</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
