import React from 'react'
import Navbar from "./Navbar";
import Herosection from "./Herosection";
import Places from "./Places";
import Features from "./Features";
import Destination from "./Destination";
import Holidays from "./Holidays";
import Thems from "./Thems";
import Testimonials from "./Testimonials";
import Contact from "./Contact";
import Footer from "./Footer";
import WhatsAppButton from './WhatsAppButton';

const Home = () => {
  return (
    <>
     <Navbar/>
      <Herosection/>
      <Places/>
      <Features/>
      <Destination/>
      <Holidays/>
      <Thems />
      <Testimonials/>
      <Contact/>
      <Footer />
      <WhatsAppButton/>

    </>
  )
}

export default Home