import React from 'react'
import I1 from '../assets/images/South AfricaI.png';
import I2 from '../assets/images/LadakhI.png';
import I3 from '../assets/images/DarjeelingI.png';
import I4 from '../assets/images/KashmirI.png';
import I5 from '../assets/images/KeralaI.png';
import I6 from '../assets/images/DubaiI.png';
import I7 from '../assets/images/UttarakhandI.png';
import I8 from '../assets/images/FranceI.png';
import Navbar from './Navbar';
import Footer from './Footer';
import WhatsAppButton from './WhatsAppButton';

const Itinerary = () => {
    return (
        <>
        <Navbar/>
        <div className="max-w-[1170px] mx-auto py-5">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 p-3">
                <div className='p-2'>
                    <div class="relative">
                        <img src={I1} alt="Itinerary 1" class="w-full" />
                        <div class="absolute bottom-0 left-0 w-full ">
                            <p class="text-white font-bold text-center text-2xl p-2">South Africa</p>
                        </div>
                    </div>
                </div>
                <div className='p-2'> 
                    <div class="relative">
                    <img src={I2} alt="Itinerary 1" class="w-full" />
                    <div class="absolute bottom-0 left-0 w-full ">
                        <p class="text-white font-bold text-center text-2xl p-2">Ladakh</p>
                    </div>
                </div>
                </div>
                <div className='p-2'>
                     <div class="relative">
                    <img src={I3} alt="Itinerary 1" class="w-full" />
                    <div class="absolute bottom-0 left-0 w-full ">
                        <p class="text-white font-bold text-center text-2xl p-2">Darjeeling</p>
                    </div>
                </div>
                </div>
                <div className='p-2'>
                     <div class="relative">
                    <img src={I4} alt="Itinerary 1" class="w-full" />
                    <div class="absolute bottom-0 left-0 w-full ">
                        <p class="text-white font-bold text-center text-2xl p-2">Kashmir</p>
                    </div>
                </div>
                </div>
                <div className='p-2'>
                     <div class="relative">
                    <img src={I5} alt="Itinerary 1" class="w-full" />
                    <div class="absolute bottom-0 left-0 w-full ">
                        <p class="text-white font-bold text-center text-2xl p-2">Kerala</p>
                    </div>
                </div>
                </div>
                <div className='p-2'>
                     <div class="relative">
                    <img src={I6} alt="Itinerary 1" class="w-full" />
                    <div class="absolute bottom-0 left-0 w-full ">
                        <p class="text-white font-bold text-center text-2xl p-2">Dubai</p>
                    </div>
                </div>
                </div>
                <div className='p-2'>
                     <div class="relative">
                    <img src={I7} alt="Itinerary 1" class="w-full" />
                    <div class="absolute bottom-0 left-0 w-full ">
                        <p class="text-white font-bold text-center text-2xl p-2">Uttarakhand</p>
                    </div>
                </div>
                </div>
                <div className='p-2'> 
                    <div class="relative">
                    <img src={I8} alt="Itinerary 1" class="w-full" />
                    <div class="absolute bottom-0 left-0 w-full ">
                        <p class="text-white font-bold text-center text-2xl p-2">France</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <Footer/>
        <WhatsAppButton/>
        </>
    )
}

export default Itinerary