// import About from "./Components/About";
// import Contact from "./Components/Contact";
// import Destination from "./Components/Destination";
// import Features from "./Components/Features";
// import Footer from "./Components/Footer";
// import Herosection from "./Components/Herosection";
// import Holidays from "./Components/Holidays";
// import Itinerary from "./Components/Itinerary";
// import Navbar from "./Components/Navbar";
// import Ourservices from "./Components/Ourservices";
// import Places from "./Components/Places";
// import Testimonials from "./Components/Testimonials";
// import Thems from "./Components/Thems";
import Home from "./Components/Home";
import About from "./Components/About";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Ourservices from "./Components/Ourservices";
import Itinerary from "./Components/Itinerary";
import Contactus from "./Components/Contactus";

function App() {
  return (
    <BrowserRouter className="App">
      <Routes>
      {/* <Navbar/>
      <Herosection/>
      <Places/>
      <Features/>
      <Destination/>
      <Holidays/>
      <Thems />
      <Testimonials/>
      <Contact/>
      <Footer />
      <Itinerary/>
      <Ourservices/>
      <About/> */}
       <Route path="/" element={<Home />} />
       <Route path="/about" element={<About />} />
       <Route path="/services" element={<Ourservices />} />
       <Route path="/itinerary" element={<Itinerary />} />
       <Route path="/contact" element={<Contactus />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
