import React from 'react'
import Navbar from './Navbar'
import Contact from './Contact'
import Footer from './Footer'
import WhatsAppButton from './WhatsAppButton'

const Contactus = () => {
  return (
   <>
   <Navbar/>
   <Contact/>
   <Footer/>
   <WhatsAppButton/>
   </>
  )
}

export default Contactus