import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HeroImg from '../assets/images/heroimg.png';
import herobali from '../assets/images/herobali.png';
import herogreece from '../assets/images/herogreece.png';
import heroparis from '../assets/images/heroparis.png';
import heroturkey from '../assets/images/heroturkey.png';
import herodubai from '../assets/images/herodubai.png';
import heroafrica from '../assets/images/heroafrica.png';
import heroindia from '../assets/images/heroindia.png';
import '../Components/Herosection.css';

const Herosection = () => {
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '10%', // Adjust this value to scale the center image
    slidesToShow: 5,
    speed: 500,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          centerPadding: '20%'
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: '10%'
        }
      }
    ]
  };

  return (
    <div id="" className="relative bg-cover bg-center overflow-hidden h-[90vh]" style={{ backgroundImage: `url(${HeroImg})` }}>
      <h1 className='text-white font-roboto text-3xl md:text-4xl text-center my-5 mx-[2rem] md:mx-[28rem]'>DISCOVER THE WORLD WITH <span className='text-[#FFCE50]'>TRAVEL OUTBOUND</span></h1>
      <Slider {...settings} className="slider py-5">
        <div className=" test w-full rounded-md  transition-transform transform scale-100 p-[2.958rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
          <img src={heroindia} alt="India" className='w-full h-[16rem]' />
        </div>
        <div className=" test w-full rounded-md  transition-transform transform scale-100 p-[2.958rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
          <img src={heroafrica} alt="Africa" className='w-full h-[16rem]' />
        </div>
        <div className=" test w-full rounded-md transition-transform transform scale-100 p-[2.958rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
          <img src={herodubai} alt="Dubai" className='w-full h-[16rem]' />
        </div>
        <div className=" test w-full rounded-md transition-transform transform scale-100 p-[2.958rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
          <img src={heroturkey} alt="Turkey" className='w-full h-[16rem]' />
        </div>
        <div className=" test w-full rounded-md  transition-transform transform scale-100 p-[2.958rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
          <img src={heroparis} alt="Paris" className='w-full h-[16rem]' />
        </div>
        <div className=" test w-full rounded-md  transition-transform transform scale-100 p-[2.958rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
          <img src={herogreece} alt="Greece" className='w-full h-[16rem]' />
        </div>
        <div className=" test w-full rounded-md  transition-transform transform scale-100 p-[2.958rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
          <img src={herobali} alt="Bali" className='w-full h-[16rem]' />
        </div>
      </Slider>
    </div>
  );
}

export default Herosection;
