import React from 'react';
import ourservicebg from '../assets/images/ourservicesbg.png';
import flightsIcon from '../assets/images/flights-icon.png'; // Example image URL
import hotelsIcon from '../assets/images/hotels-icon.png'; // Example image URL
import visasIcon from '../assets/images/visas-icon.png'; // Example image URL
import packagesIcon from '../assets/images/packages-icon.png'; // Example image URL
import travelInsuranceIcon from '../assets/images/travel-insurance-icon.png'; // Example image URL
import shipIcon from '../assets/images/ship.png';
import Navbar from './Navbar';
import Footer from './Footer';
import WhatsAppButton from './WhatsAppButton';

const Ourservices = () => {
    return (
       <>
       <Navbar/>
        <div className='bg-cover bg-center h-full flex flex-col items-center justify-center' style={{ backgroundImage: `url(${ourservicebg})` }}>
               <h1 className='text-white text-5xl py-10 font-bold'>OUR SERVICES</h1>
               <div className="flex flex-wrap gap-5 justify-center py-5">
                    <div className='md:w-[30%] w-[42%] h-[9rem] bg-[white] rounded-lg flex flex-col items-center justify-center'>
                        <img src={flightsIcon} alt="" className='w-[40%]' />
                        <h5 className='text-[#005393] text-[1.3rem] p-1'>Flights</h5>
                    </div>
                    <div  className='md:w-[30%] w-[42%] h-[9rem] bg-[white] rounded-lg flex flex-col items-center justify-center'>
                        <img src={hotelsIcon} alt="" className='w-[40%]' />
                        <h5 className='text-[#005393] text-[1.3rem] p-1'>Hotels</h5>
                    </div>
                    <div  className='md:w-[30%] w-[42%] h-[9rem] bg-[white] rounded-lg flex flex-col items-center justify-center'>
                        <img src={visasIcon} alt="" className='w-[40%]' />
                        <h5 className='text-[#005393] text-[1.3rem] p-1'>Visas</h5>
                    </div>

                   
                </div>

                <div className="flex flex-wrap gap-5 justify-center py-5">
                    <div className='md:w-[30%] w-[42%] h-[9rem] bg-[white] rounded-lg flex flex-col items-center justify-center'>
                        <img src={packagesIcon} alt="" className='w-[40%]' />
                        <h5 className='text-[#005393] text-[1.3rem] p-1'>Packages</h5>
                    </div>
                    <div  className='md:w-[30%] w-[42%] h-[9rem] bg-[white] rounded-lg flex flex-col items-center justify-center'>
                        <img src={travelInsuranceIcon} alt="" className='w-[40%]' />
                        <h5 className='text-[#005393] text-[1.3rem] p-1'>Travel Insurance</h5>
                    </div>
                    <div  className='md:w-[30%] w-[42%] h-[9rem] bg-[white] rounded-lg flex flex-col items-center justify-center'>
                        <img src={shipIcon} alt="" className='w-[40%]' />
                        <h5 className='text-[#005393] text-[1.3rem] p-1'>Ships</h5>
                    </div>

                   
                </div>
        </div>
        <Footer/>
        <WhatsAppButton/>
        </>
    );
};

export default Ourservices;
