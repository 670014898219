import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import southafrica from '../assets/images/South Africa.png';
import rajasthan from '../assets/images/Rajasthan.png';
import maldives from '../assets/images/Maldives.png';
import shimla from '../assets/images/Shimla.png';
import '../Components/places.css'; // Import CSS file directly

const Places = () => {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
       <h1 className='text-[#005293] text-3xl font-medium font-roboto text-center py-4 mt-3'>Popular Places</h1>
      <div className="slider-container mx-[3.5rem]">
        <Slider {...settings}>
          <div className='p-10 relative'>
            <div className='relative'>
              <img src={southafrica} alt="" className='w-full' />
              <button className='absolute bottom-[1rem] left-[2rem] right-[2rem] py-1 mx-auto bg-[white] text-black font-medium font-rubik rounded'>
                South Africa
              </button>
            </div>
            <div className="absolute top-[4rem] right-[4rem] flex items-center">
              <div className="bg-white rounded-full px-[7px] py-[5px]" style={{ width: "32px", height: "32px" }}>
                <i className="fas fa-heart text-red-500"></i>
              </div>
            </div>
          </div>

          <div className='p-10 relative'>
            <div className='relative'>
              <img src={rajasthan} alt="" className='w-full' />
              <button className='absolute bottom-[1rem] left-[2rem] right-[2rem] py-1 mx-auto bg-[white] text-black font-medium font-rubik rounded'>
                Rajasthan
              </button>
            </div>
            <div className="absolute top-[4rem] right-[4rem] flex items-center">
              <div className="bg-white rounded-full px-[7px] py-[5px]" style={{ width: "32px", height: "32px" }}>
                <i className="fas fa-heart text-red-500"></i>
              </div>
            </div>
          </div>


          <div className='p-10 relative'>
            <div className='relative'>
              <img src={maldives} alt="" className='w-full' />
              <button className='absolute bottom-[1rem] left-[2rem] right-[2rem] py-1 mx-auto bg-[white] text-black font-medium font-rubik rounded'>
                Maldives
              </button>
            </div>
            <div className="absolute top-[4rem] right-[4rem] flex items-center">
              <div className="bg-white rounded-full px-[7px] py-[5px]" style={{ width: "32px", height: "32px" }}>
                <i className="fas fa-heart text-red-500"></i>
              </div>
            </div>
          </div>


          <div className='p-10 relative'>
            <div className='relative'>
              <img src={shimla} alt="" className='w-full' />
              <button className='absolute bottom-[1rem] left-[2rem] right-[2rem] py-1 mx-auto bg-[white] text-black font-medium font-rubik rounded'>
                Shimla
              </button>
            </div>
            <div className="absolute top-[4rem] right-[4rem] flex items-center">
              <div className="bg-white rounded-full px-[7px] py-[5px]" style={{ width: "32px", height: "32px" }}>
                <i className="fas fa-heart text-red-500"></i>
              </div>
            </div>
          </div>











        </Slider>
      </div>
    </>
  )
}

export default Places;
