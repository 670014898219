import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Destination1 from '../assets/images/destination1.png';
import Destination2 from '../assets/images/destination2.png';
import Destination3 from '../assets/images/destination3.png';
import Destination4 from '../assets/images/destination4.png';
import '../Components/Destination.css';
import { NavLink } from 'react-router-dom';

const Destination = () => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
      
    const settings = {
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '10%', // Adjust this value to scale the center image
        slidesToShow: 3,
        speed: 500,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '20%'
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '10%'
                }
            }
        ]
    };

    return (
        <div className="flex flex-col lg:flex-row sm:p-0 mt-24 mb-10 bg-gray">
            <div className="w-full lg:w-1/5   flex justify-center lg:justify-start items-center mb-4 sm:mb-0">
                <div className="shadow-2xl  bg-white w-full  p-6 rounded-xl text-center lg:text-left">

                    <h1 className="text-2xl text-[#005293] font-bold mb-4">Destinations</h1>
                    <p className="text-lg  mb-4">We offer a perfect variety of experiences</p>
                    <button  onClick={scrollToTop} className="bg-[#11B99F]   hover:bg-black text-white font-bold py-2 px-4 rounded"><NavLink to="/contact">Explore More</NavLink></button>
                </div>
            </div>
            <div className="w-full lg:w-4/5 relative   overflow-hidden">
                <div className="absolute right-0 top-16 bottom-16 sm:top-28 sm:bottom-28 w-full bg-[#E6E7E9]">
                </div>
                <div className="flex flex-col items-center destination-slider">
                    <div className="slick-slider w-4/5 relative slick-initialized" dir="ltr">
                        {/* test code */}
                        <Slider {...settings} className="sliderdestination py-5">
                            <div className=" test w-full rounded-md transition-transform transform scale-100 p-[2.813rem] md:p-[1.5rem]" tabindex="-1" style={{ width: '100%', display: 'inline-block' }}>
                                <img src={Destination1} alt="India" className='w-full h-[16rem]' />
                            </div>
                            <div className=" test w-full rounded-md transition-transform transform scale-100 p-[2.813rem] md:p-[1.5rem]" tabindex="-1" style={{ width: '100%', display: 'inline-block' }}>
                                <img src={Destination2} alt="Africa" className='w-full h-[16rem]' />
                            </div>
                            <div className=" test w-full rounded-md transition-transform transform scale-100 p-[2.813rem] md:p-[1.5rem]" tabindex="-1" style={{ width: '100%', display: 'inline-block' }}>
                                <img src={Destination3} alt="Dubai" className='w-full h-[16rem]' />
                            </div>
                            <div className=" test w-full rounded-md transition-transform transform scale-100 p-[2.813rem] md:p-[1.5rem]" tabindex="-1" style={{ width: '100%', display: 'inline-block' }}>
                                <img src={Destination4} alt="Turkey" className='w-full h-[16rem]' />
                            </div>
                        </Slider>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default Destination;
