import React from 'react'
import bgtestimonials from '../assets/images/testimonialbg.png';
import client1 from '../assets/images/Manoj Pahuja.png';
import client2 from '../assets/images/Swara.png';
import client3 from '../assets/images/Pratixa.png';
import client4 from '../assets/images/Mrs Usha and Mr Vijay Patel.png';
import client5 from '../assets/images/Abhishek Shukla.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Testimonials = () => {

    const settings = {
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '10%', // Adjust this value to scale the center image
        slidesToShow: 3,
        speed: 500,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              centerPadding: '20%'
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              centerPadding: '10%'
            }
          }
        ]
      };

    return (
        <div
            style={{ backgroundImage: `url(${bgtestimonials})` }}
            className=""
        >

<Slider {...settings} className="slider py-[0.25rem] px-0 md:px-[6rem]">
        <div className=" test w-full rounded-md  transition-transform transform scale-100 p-[2.813rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
         
         <div class="max-w-[22rem] mx-auto py-[4rem] px-0 md:px-5">
                <div className="relative shadow-2xl p-4 border-b-8 border-[#11B99F] bg-white  scale-100" tabIndex="-1">
                    <div className="absolute top-0 right-5 sm:right-16 transform -translate-x-1/2">
                        <img src={client3} alt="Pratixa" className="mx-auto h-14 w-14 sm:h-20 sm:w-20 rounded-full object-cover mb-4 -mt-8" />
                    </div>
                    <div className="">
                        <div className="text-blue-800 mb-4 text-right">
                            <div className="flex justify-end">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="sm:text-7xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.4167 6.67891C20.4469 7.77257 21.0001 9 21.0001 10.9897C21.0001 14.4891 18.5436 17.6263 14.9695 19.1768L14.0768 17.7992C17.4121 15.9946 18.0639 13.6539 18.3245 12.178C17.7875 12.4557 17.0845 12.5533 16.3954 12.4895C14.591 12.3222 13.1689 10.8409 13.1689 9C13.1689 7.067 14.7359 5.5 16.6689 5.5C17.742 5.5 18.7681 5.99045 19.4167 6.67891ZM9.41669 6.67891C10.4469 7.77257 11.0001 9 11.0001 10.9897C11.0001 14.4891 8.54359 17.6263 4.96951 19.1768L4.07682 17.7992C7.41206 15.9946 8.06392 13.6539 8.32447 12.178C7.78747 12.4557 7.08452 12.5533 6.39539 12.4895C4.59102 12.3222 3.16895 10.8409 3.16895 9C3.16895 7.067 4.73595 5.5 6.66895 5.5C7.742 5.5 8.76814 5.99045 9.41669 6.67891Z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <p className="mb-4 h-[6rem] text-xs sm:text-xs text-center">Hi Nishant, Thankyou for all your efforts. Our trip was truly joyful and blessed with divine love and light. We look forward to connecting with you again for another trip. My sincere gratitude to you and full team that supported you.</p>
                    <p className="font-bold flex text-xs sm:text-l justify-end">Pratixa</p>
                </div>
            </div>

        </div>
        <div className=" test w-full transition-transform transform scale-100 p-[2.813rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
          
          <div class="max-w-[22rem] mx-auto py-[4rem] px-0 md:px-5">
                <div className="relative shadow-2xl p-4 border-b-8 border-[#11B99F] bg-white  scale-100" tabIndex="-1">
                    <div className="absolute top-0 right-5 sm:right-16 transform -translate-x-1/2">
                        <img src={client5} alt="Pratixa" className="mx-auto h-14 w-14 sm:h-20 sm:w-20 rounded-full object-cover mb-4 -mt-8" />
                    </div>
                    <div className="">
                        <div className="text-blue-800 mb-4 text-right">
                            <div className="flex justify-end">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="sm:text-7xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.4167 6.67891C20.4469 7.77257 21.0001 9 21.0001 10.9897C21.0001 14.4891 18.5436 17.6263 14.9695 19.1768L14.0768 17.7992C17.4121 15.9946 18.0639 13.6539 18.3245 12.178C17.7875 12.4557 17.0845 12.5533 16.3954 12.4895C14.591 12.3222 13.1689 10.8409 13.1689 9C13.1689 7.067 14.7359 5.5 16.6689 5.5C17.742 5.5 18.7681 5.99045 19.4167 6.67891ZM9.41669 6.67891C10.4469 7.77257 11.0001 9 11.0001 10.9897C11.0001 14.4891 8.54359 17.6263 4.96951 19.1768L4.07682 17.7992C7.41206 15.9946 8.06392 13.6539 8.32447 12.178C7.78747 12.4557 7.08452 12.5533 6.39539 12.4895C4.59102 12.3222 3.16895 10.8409 3.16895 9C3.16895 7.067 4.73595 5.5 6.66895 5.5C7.742 5.5 8.76814 5.99045 9.41669 6.67891Z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <p className="mb-4 h-[6rem] text-xs sm:text-xs text-center">Thank you for arranging everything.. We had a wonderful experience through Travel Outbound.. you made my Marriage Anniversary special in Goa.. Trip was memorable❤️</p>
                    <p className="font-bold flex text-xs sm:text-l justify-end">Abhishek Shukla</p>
                </div>
            </div>

        </div>
        <div className=" test w-full  transition-transform transform scale-100 p-[2.813rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
         
         <div class="max-w-[22rem] mx-auto py-[4rem] px-0 md:px-5">
                <div className="relative shadow-2xl p-4 border-b-8 border-[#11B99F] bg-white  scale-100" tabIndex="-1">
                    <div className="absolute top-0 right-5 sm:right-16 transform -translate-x-1/2">
                        <img src={client4} alt="Pratixa" className="mx-auto h-14 w-14 sm:h-20 sm:w-20 rounded-full object-cover mb-4 -mt-8" />
                    </div>
                    <div className="">
                        <div className="text-blue-800 mb-4 text-right">
                            <div className="flex justify-end">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="sm:text-7xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.4167 6.67891C20.4469 7.77257 21.0001 9 21.0001 10.9897C21.0001 14.4891 18.5436 17.6263 14.9695 19.1768L14.0768 17.7992C17.4121 15.9946 18.0639 13.6539 18.3245 12.178C17.7875 12.4557 17.0845 12.5533 16.3954 12.4895C14.591 12.3222 13.1689 10.8409 13.1689 9C13.1689 7.067 14.7359 5.5 16.6689 5.5C17.742 5.5 18.7681 5.99045 19.4167 6.67891ZM9.41669 6.67891C10.4469 7.77257 11.0001 9 11.0001 10.9897C11.0001 14.4891 8.54359 17.6263 4.96951 19.1768L4.07682 17.7992C7.41206 15.9946 8.06392 13.6539 8.32447 12.178C7.78747 12.4557 7.08452 12.5533 6.39539 12.4895C4.59102 12.3222 3.16895 10.8409 3.16895 9C3.16895 7.067 4.73595 5.5 6.66895 5.5C7.742 5.5 8.76814 5.99045 9.41669 6.67891Z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <p className="mb-4 h-[6rem] text-xs sm:text-xs text-center">Our Kerela trip was awesome, and all arrangements were great..We enjoyed a lot</p>
                    <p className="font-bold flex text-xs sm:text-l justify-end">Mrs Usha and Mr Vijay Patel</p>
                </div>
            </div>

        </div>
        <div className=" test w-full  transition-transform transform scale-100 p-[2.813rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
         
         <div class="max-w-[22rem] mx-auto py-[4rem] px-0 md:px-5">
                <div className="relative shadow-2xl p-4 border-b-8 border-[#11B99F] bg-white  scale-100" tabIndex="-1">
                    <div className="absolute top-0 right-5 sm:right-16 transform -translate-x-1/2">
                        <img src={client2} alt="Pratixa" className="mx-auto h-14 w-14 sm:h-20 sm:w-20 rounded-full object-cover mb-4 -mt-8" />
                    </div>
                    <div className="">
                        <div className="text-blue-800 mb-4 text-right">
                            <div className="flex justify-end">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="sm:text-7xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.4167 6.67891C20.4469 7.77257 21.0001 9 21.0001 10.9897C21.0001 14.4891 18.5436 17.6263 14.9695 19.1768L14.0768 17.7992C17.4121 15.9946 18.0639 13.6539 18.3245 12.178C17.7875 12.4557 17.0845 12.5533 16.3954 12.4895C14.591 12.3222 13.1689 10.8409 13.1689 9C13.1689 7.067 14.7359 5.5 16.6689 5.5C17.742 5.5 18.7681 5.99045 19.4167 6.67891ZM9.41669 6.67891C10.4469 7.77257 11.0001 9 11.0001 10.9897C11.0001 14.4891 8.54359 17.6263 4.96951 19.1768L4.07682 17.7992C7.41206 15.9946 8.06392 13.6539 8.32447 12.178C7.78747 12.4557 7.08452 12.5533 6.39539 12.4895C4.59102 12.3222 3.16895 10.8409 3.16895 9C3.16895 7.067 4.73595 5.5 6.66895 5.5C7.742 5.5 8.76814 5.99045 9.41669 6.67891Z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <p className="mb-4 h-[6rem] text-xs sm:text-xs text-center">We had an amazing trip organized by you, especially enjoying Munnar's hill station and Alleppey's beach. Everything was well-organized, including the hotels. Thank you for a wonderful Kerala trip!</p>
                    <p className="font-bold flex text-xs sm:text-l justify-end">Swara</p>
                </div>
            </div>

        </div>
        <div className=" test w-full  transition-transform transform scale-100 p-[2.813rem] md:p-[1.5rem]" tabindex="-1"  style={{ width: '100%', display: 'inline-block' }}>
          
          <div class="max-w-[22rem] mx-auto py-[4rem] px-0 md:px-5">
                <div className="relative shadow-2xl p-4 border-b-8 border-[#11B99F] bg-white scale-100" tabIndex="-1">
                    <div className="absolute top-0 right-5 sm:right-16 transform -translate-x-1/2">
                        <img src={client1} alt="Pratixa" className="mx-auto h-14 w-14 sm:h-20 sm:w-20 rounded-full object-cover mb-4 -mt-8" />
                    </div>
                    <div className="">
                        <div className="text-blue-800 mb-4 text-right">
                            <div className="flex justify-end">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="sm:text-7xl" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.4167 6.67891C20.4469 7.77257 21.0001 9 21.0001 10.9897C21.0001 14.4891 18.5436 17.6263 14.9695 19.1768L14.0768 17.7992C17.4121 15.9946 18.0639 13.6539 18.3245 12.178C17.7875 12.4557 17.0845 12.5533 16.3954 12.4895C14.591 12.3222 13.1689 10.8409 13.1689 9C13.1689 7.067 14.7359 5.5 16.6689 5.5C17.742 5.5 18.7681 5.99045 19.4167 6.67891ZM9.41669 6.67891C10.4469 7.77257 11.0001 9 11.0001 10.9897C11.0001 14.4891 8.54359 17.6263 4.96951 19.1768L4.07682 17.7992C7.41206 15.9946 8.06392 13.6539 8.32447 12.178C7.78747 12.4557 7.08452 12.5533 6.39539 12.4895C4.59102 12.3222 3.16895 10.8409 3.16895 9C3.16895 7.067 4.73595 5.5 6.66895 5.5C7.742 5.5 8.76814 5.99045 9.41669 6.67891Z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <p className="mb-4 h-[6rem] text-xs sm:text-xs text-center">Very good support and guidance from you Nishant throughout the trip, Thankyou very much, God bless you dear.</p>
                    <p className="font-bold flex text-xs sm:text-l justify-end">Manoj Pahuja</p>
                </div>
            </div>

        </div>
       
      </Slider>

           

            

        </div>
    )
}

export default Testimonials
